// @ts-strict-ignore
import _ from 'lodash';
import { getAlignableItems } from '@/trend/trendDataHelper.utilities';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { isStringSeries } from '@/utilities/utilities';
import { infoToast } from '@/utilities/toast.utilities';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { TableColumnOutputV1 } from '@/sdk';
import { STRING_UOM } from '@/main/app.constants';
import i18next from 'i18next';
import { ReactSelectOption } from '@/core/IconSelect.molecule';
import {
  CapsuleTimeColorMode,
  CompareViewColorMode,
  CUSTOMIZATION_MODES,
  LABEL_LOCATIONS,
  LABEL_PROPERTIES,
  SummarySliderValues,
  TREND_PANELS,
  TREND_PANELS_SORT,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { sqTrendSeriesStore, sqWorksheetStore } from '@/core/core.stores';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { getAlignments, shouldGridlinesBeShown } from './trend.utilities';
import { getUniqueOrderedValuesByProperty } from '@/utilities/chartHelper.utilities';

export class TrendStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqTrendStore';

  /**
   * sqTrendSeriesStore and sqTrendScalar store are required to calculate lanes and alignments
   */
  rehydrateWaitFor = ['sqTrendSeriesStore', 'sqTrendScalarStore'];

  /**
   * Initializes the store by setting default values to the stored state
   */
  initialize() {
    const enabledColumns = {};
    enabledColumns[TREND_PANELS.CAPSULES] = { startTime: true };

    this.state = this.immutable({
      view: TREND_VIEWS.CALENDAR,
      isCompareMode: false,
      enabledColumns,
      propertyColumns: {},
      selectedRegion: {
        min: 0,
        max: 0,
      },
      capsuleTimeOffsets: {
        lower: 0,
        upper: 0,
      },
      dataSummary: {
        type: SummaryTypeEnum.AUTO,
        value: 0,
        discreteUnits: { value: 0, units: 'min' },
        isSlider: true,
      },
      capsuleTimeLimited: {
        enabled: false,
        itemsCount: 0,
        capsulesCount: 0,
      },
      capsuleTimeColorMode: CapsuleTimeColorMode.Signal,
      compareViewColorMode: CompareViewColorMode.Rainbow,
      capsulePreview: false,
      customizationMode: CUSTOMIZATION_MODES.OFF,
      dimDataOutsideCapsules: false,
      hideUnselectedItems: false,
      labelDisplayConfiguration: {
        name: LABEL_LOCATIONS.OFF,
        asset: LABEL_LOCATIONS.OFF,
        assetPathLevels: 1,
        line: LABEL_LOCATIONS.OFF,
        unitOfMeasure: LABEL_LOCATIONS.OFF,
        custom: LABEL_LOCATIONS.OFF,
        customLabels: [],
      },
      showCapsuleLaneLabels: false,
      xValue: null,
      panelProps: {},
      panelSorters: TREND_PANELS_SORT,
      capsulePanelFilters: {},
      capsulePanelDistinctStringValueMap: {},
      capsulePanelHeaders: [],
      capsulePanelOffset: 0,
      capsulePanelMaxOffset: 0,
      capsulePanelHasNext: false,
      capsulePanelIsLoading: false,
      pointValues: {},
      showGridlines: false,
      capsuleProperties: [],
      colorByProperty: undefined,
      separateByProperty: undefined,
      firstColumn: undefined,
    });
  }

  get view() {
    return this.state.get('view');
  }

  get selectedRegion() {
    return this.state.get('selectedRegion');
  }

  get capsuleTimeOffsets() {
    return this.state.get('capsuleTimeOffsets');
  }

  get areCapsuleTimeOffsetsChanged() {
    return this.state.get('capsuleTimeOffsets', 'lower') !== 0 || this.state.get('capsuleTimeOffsets', 'upper') !== 0;
  }

  get capsuleTimeLimited() {
    return this.state.get('capsuleTimeLimited');
  }

  get capsulePanelFilters() {
    return this.state.get('capsulePanelFilters');
  }

  get capsuleTimeColorMode(): CapsuleTimeColorMode {
    return this.state.get('capsuleTimeColorMode');
  }

  get compareViewColorMode(): CompareViewColorMode {
    return this.state.get('compareViewColorMode');
  }

  get dimDataOutsideCapsules() {
    return this.state.get('dimDataOutsideCapsules');
  }

  get hideUnselectedItems() {
    return this.state.get('hideUnselectedItems');
  }

  get xValue() {
    return this.state.get('xValue');
  }

  get annotationId() {
    return this.state.get('annotationId');
  }

  get customizationMode() {
    return this.state.get('customizationMode');
  }

  get labelDisplayConfiguration() {
    return this.state.get('labelDisplayConfiguration');
  }

  get showLaneLabels() {
    return this.state.get('showLaneLabels');
  }

  get showCapsuleLaneLabels() {
    return this.state.get('showCapsuleLaneLabels');
  }

  get pointValues() {
    return this.state.get('pointValues');
  }

  get capsulePanelOffset() {
    return this.state.get('capsulePanelOffset');
  }

  get capsulePanelMaxOffset() {
    return this.state.get('capsulePanelMaxOffset');
  }

  get capsulePanelHasNext() {
    return this.state.get('capsulePanelHasNext');
  }

  get capsulePanelIsLoading() {
    return this.state.get('capsulePanelIsLoading');
  }

  get isCompareMode() {
    return this.state.get('isCompareMode');
  }

  get capsulePreview() {
    return this.state.get('capsulePreview');
  }

  get showGridlines() {
    return this.state.get('showGridlines');
  }

  get dataSummary() {
    return this.state.get('dataSummary');
  }

  get capsuleProperties(): ReactSelectOption<string>[] {
    return this.state.get('capsuleProperties');
  }

  get colorByProperty() {
    return this.state.get('colorByProperty');
  }

  get separateByProperty() {
    return this.state.get('separateByProperty');
  }

  get capsulePanelDistinctStringValueMap() {
    return this.state.get('capsulePanelDistinctStringValueMap');
  }

  get firstColumn(): string {
    return this.state.get('firstColumn');
  }

  /** Possible lanes if `max` alignable items had their own lane */
  get lanes() {
    return this.getLanes(getAlignableItems({}).length);
  }

  /** Possible alignments if `max` alignable items had their own axis */
  get alignments() {
    return getAlignments(getAlignableItems({ includeConditions: false }).length);
  }

  get isSummarizationActive(): boolean {
    const summary = this.state.get('dataSummary');

    return summary.value > 0 || summary.discreteUnits.value > 0;
  }

  /**
   * The formula being used to summarize the plot data, in the form needed
   * to attach it to another signal
   */
  get discreteSummaryFormula() {
    const timeUnit = this.state.get('dataSummary', 'discreteUnits');

    return timeUnit.value <= 0 ? '' : `.aggregate(average(), periods(${timeUnit.value}${timeUnit.units}), startKey())`;
  }

  /** A list of unique lane designations used. */
  get uniqueLanes(): any[] {
    const items = getAlignableItems({});

    return getUniqueOrderedValuesByProperty(items, 'lane', this.getLanes(items.length));
  }

  /** The next available lane */
  get nextLane() {
    const maximumCount = getAlignableItems({}).length + 1;

    return _.first(_.difference(this.getLanes(maximumCount), this.uniqueLanes));
  }

  /** An Array of assigned y-axis alignment designations. */
  get uniqueAlignments() {
    const items = getAlignableItems({});

    return getUniqueOrderedValuesByProperty(items, 'axisAlign', getAlignments(items.length));
  }

  /** The next available alignment */
  get nextAlignment() {
    const maximumCount = getAlignableItems({}).length + 1;

    return _.first(_.difference(getAlignments(maximumCount), this.uniqueAlignments));
  }

  getMaxCapsuleTimeItems(): number {
    return 300;
  }

  private getLanes(max: number) {
    return _.times(max, (idx) => idx + 1);
  }

  /**
   * Property columns are custom columns whose values come from a property of the item. The `propertyColumns`
   * property contains all the enabled and disabled columns.
   */
  propertyColumns = (panel) => {
    return _.sortBy(this.state.get('propertyColumns', panel), 'title');
  };

  getPanelProps = (panel) => {
    return this.state.get('panelProps', panel) || {};
  };

  getPanelSort = (panel) => {
    return this.state.get('panelSorters', panel);
  };

  getColumnFilter = (key: string): TableColumnFilter | undefined => {
    return this.state.get('capsulePanelFilters', key);
  };

  isColumnEnabled = (panel, column) => {
    return this.state.exists('enabledColumns', panel, column);
  };

  isStringColumn = (key: string): boolean => {
    const headers: TableColumnOutputV1[] = this.state.get('capsulePanelHeaders');
    return _.chain(headers).filter({ name: key }).head().get('type').value() === STRING_UOM;
  };

  customColumns = (panel) => {
    return _.chain(this.state.get('enabledColumns', panel)).pickBy(_.isObject).values().value();
  };

  enabledColumns = (panel) => {
    return this.state.get('enabledColumns', panel);
  };

  isRegionSelected = () => {
    return this.state.get('selectedRegion', 'min') !== this.state.get('selectedRegion', 'max');
  };

  isTrendViewCapsuleTime = () => {
    return this.state.get('view') === TREND_VIEWS.CAPSULE && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREND;
  };

  isTrendViewChainView = () => {
    return this.state.get('view') === TREND_VIEWS.CHAIN && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREND;
  };

  /**
   * Retrieves the summary mode and level as a string to use a label or informative tooltip
   */
  getSummaryAsString = () => {
    const summary = this.state.get('dataSummary');
    if (!this.isSummarizationActive) {
      return '';
    }

    if (summary.type === SummaryTypeEnum.DISCRETE) {
      return `${i18next.t('TOOLBAR.SUMMARY.DISCRETE_SUMMARY_AS_STRING_LABEL')} ${summary.discreteUnits.value} ${
        summary.discreteUnits.units
      }`;
    }

    if (summary.type === SummaryTypeEnum.AUTO) {
      return `${i18next.t('TOOLBAR.SUMMARY.AUTO_SUMMARY_AS_STRING_LABEL')} ${summary.value}`;
    }
  };

  /**
   * Calculates the formula downsampling parameter for a series.
   * @param seriesId - The series id
   * @returns the downsampling isSpikeCatcher and trendSummarizerFactor parameters
   */
  getDownSamplingParameters = (
    seriesId: string,
  ): {
    isSpikeCatcher: boolean;
    trendSummarizerFactor: string;
  } => {
    const summary = this.state.get('dataSummary');
    const isString = isStringSeries(sqTrendSeriesStore.findItem(seriesId));
    const isSpikeCatcher = summary.type !== SummaryTypeEnum.AUTO || summary.value === 0 || isString;
    const trendSummarizerFactor = !isSpikeCatcher
      ? `, ${SummarySliderValues[SummaryTypeEnum.AUTO].value[summary.value]}`
      : '';

    return { isSpikeCatcher, trendSummarizerFactor };
  };

  /**
   * Builds the formula to be used to summarize the data if DataSummarizer is activated
   * @param seriesID - Used to filter out String signals
   */
  buildSummarizeFormula = (seriesID: string) => {
    const summary = this.state.get('dataSummary');
    const isString = isStringSeries(sqTrendSeriesStore.findItem(seriesID));
    if (isString || summary.type === SummaryTypeEnum.NONE || summary.discreteUnits.value <= 0) {
      return '';
    } else {
      return this.discreteSummaryFormula;
    }
  };

  /**
   * Builds either the spikeCatcher formula or the trendSummarizer formula needed to downsample a particular trend
   * @param laneWidth - The width of a lane of a pixels given in time
   *                                    [Example: '14ms']
   * @param seriesId - Id for the series being downsampled
   * @param stats - string af stats to calculate for the series with leading comma
   *                                    [Example: ', minValue(), range(), average(), stdDev()' ]
   * @param condition - a condition to check against to decide what to display in the downsampled data
   *                                    [Example: 'condition(19ms, capsule(15ms, 15ms))']
   *
   * @returns downsampleFormula - the string representation of the Formula we will be chaining to the signal
   *                                    [Example: `.spikeCatcher(14ms)` ]
   */
  buildDownsampleFormula = (laneWidth: string, seriesId: any, condition?: string, stats?: string): string => {
    const { isSpikeCatcher, trendSummarizerFactor } = this.getDownSamplingParameters(seriesId);

    condition = !condition ? '' : `, ${condition}`; // condition does not come in with a leading comma
    stats = !stats ? '' : stats; // Stats come in with a leading comma
    const beginFormula = isSpikeCatcher ? '.spikeCatcher(' : '.trendSummarizer(';
    const endFormula = `${laneWidth}${condition}${trendSummarizerFactor}${stats})`;

    return `${beginFormula}${endFormula}`;
  };

  /**
   * Sets the current value of the X-axis, which is the x-value under the current cursor location.
   * @param {Object} payload - Object container for arguments
   * @param {Number} payload.xValue - Value of x-axis location, in milliseconds.
   */
  private setXValue = (payload) => {
    this.state.set('xValue', payload.xValue);
  };

  /**
   * Sets the customization mode for the trend
   *
   * @param {Object} payload - Object container for arguments
   * @param {Boolean} payload.customizationMode - one of the CUSTOMIZATION_MODES
   */
  private setCustomizationMode = (payload) => {
    this.state.set('customizationMode', payload.customizationMode);
  };

  /**
   * Sets the view of the trend to be displayed to the user.
   *
   * @param {Object} payload - Object container for arguments
   * @param {string} payload.view - The view of the trend to be displayed to the user.
   */
  private setView = (payload) => {
    this.state.set('view', payload.view);
  };

  /**
   * Turns the display of a particular column on or off.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.panel - The name of the panel to which the column belongs
   * @param {String} payload.column - The key that identifies the column.
   * @param {Object} [payload.columnDefinition] - A custom column definition
   * @param {String} payload.columnDefinitions.key - A unique combination of statistic key and item id
   * @param {String} payload.columnDefinitions.referenceSeries - The item id that this column references
   * @param {String} payload.columnDefinitions.statisticKey - The statistic key
   * @param {Boolean} payload.enabled - Whether or not it is enabled
   */
  private setColumnEnabled = (payload) => {
    const cursor = this.state.select('enabledColumns', payload.panel);
    const columnDefinition = payload.columnDefinition || true;

    if (payload.enabled) {
      cursor.set(payload.column, columnDefinition);
    } else {
      cursor.unset(payload.column);
    }
  };

  /**
   * Adds to the list of property columns
   *
   * @param  {Object} payload - argument container
   * @param  {String} payload.panel - The name of the panel to which the column belongs
   * @param  {Object} payload.property - column definition for the property
   */
  private addPropertiesColumn = (payload) => {
    this.state.set(['propertyColumns', payload.panel, payload.property.key], payload.property);
  };

  private addMultiplePropertiesColumn = (payload) => {
    const propertyColumns = this.state.get(['propertyColumns', payload.panel]);
    const newColumns = {};
    _.forEach(payload.properties, (property) => {
      newColumns[property.key] = property;
    });
    this.state.set(['propertyColumns', payload.panel], {
      ...propertyColumns,
      ...newColumns,
    });
  };

  /**
   * Removes from the list of property columns
   *
   * @param  {Object} payload - argument container
   * @param  {String} payload.panel - The name of the panel to which the column belongs
   * @param  {Object} payload.property - column definition for the property
   */
  private removePropertiesColumn = (payload) => {
    const cursor = this.state.select('propertyColumns', payload.panel);
    cursor.unset(payload.property.key);
  };

  /**
   * Sets visibility of gridlines on the chart.
   * Does not show gridlines if we have multiple unaligned y axes in any lane, and instead displays a warning.
   * @param payload - argument container
   * @param payload.showGridlines - true if we want to turn on gridlines, false otherwise
   * @param payload.skipWarning - true if we want to skip the "Gridlines can not be shown..." warning
   *    (i.e. if the caller has already shown teh warning), false/undefined otherwise
   */
  private setGridlines = (payload: { showGridlines: boolean; skipWarning: boolean }): void => {
    let showGridlines = payload.showGridlines;
    const alignableItems = getAlignableItems({ workingSelection: true });

    // If we're calling setGridlines solely to remove the gridlines, assume we've already determined
    // that there are multiple y axes in one lane so we don't show a second warning and do unnecessary computation
    if (!payload.skipWarning && !shouldGridlinesBeShown(alignableItems)) {
      infoToast({
        messageKey: 'NO_GRIDLINES_FOR_MULTIPLE_Y_AXES_ONE_LANE',
      });
      showGridlines = false;
    }

    this.state.set('showGridlines', showGridlines);
  };

  /**
   * Removes any columns that are related to the items being removed. It looks for any columns that have an
   * referenceSeries that matches one of the ids being removed.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} payload.ids - Array of item ids
   */
  private removeItemColumns = (payload) => {
    let ids = payload.ids;
    if (!payload.ids && _.isArray(payload.items)) {
      ids = _.map(payload.items, _.property('id'));
    }

    _.forEach(this.state.get('enabledColumns'), (columns, panel) => {
      _.forEach(columns, (columnDefinition, column) => {
        if (_.isObject(columnDefinition) && _.includes(ids, columnDefinition.referenceSeries)) {
          this.setColumnEnabled({ panel, column, enabled: false });
        }
      });
    });
  };

  /**
   * Resets the offset and maxOffset for the capsule panel.
   */
  private resetCapsulePanelOffset = () => {
    this.state.set('capsulePanelMaxOffset', 0);
    this.state.set('capsulePanelOffset', 0);
  };

  /**
   * Sets the offset for the capsule panel.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Number} payload.offset - The offset in table rows being retrieved.
   */
  private setCapsulePanelOffset = (payload) => {
    this.state.set('capsulePanelOffset', payload.offset);

    const maxOffset = this.state.get('capsulePanelMaxOffset');
    if (payload.offset > maxOffset) {
      this.state.set('capsulePanelMaxOffset', payload.offset);
    }
  };

  /**
   * Sets whether there are more capsules available to fetch.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Boolean} payload.hasNext - True if there are more to fetch, false otherwise
   */
  private setCapsulePanelHasNext = (payload) => {
    this.state.set('capsulePanelHasNext', payload.hasNext);
  };

  /**
   * Sets whether data is loading for the capsules panel.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Boolean} payload.isLoading - True if data is loading, false otherwise
   */
  private setCapsulePanelIsLoading = (payload) => {
    this.state.set('capsulePanelIsLoading', payload.isLoading);
  };

  /**
   * Adds a filter for the column.key
   *
   * @param key - Key for the column that is getting filtered
   * @param filter - Filter object containing filter value and operator
   */
  private setColumnFilter = ({ key, filter }: { key: string; filter: TableColumnFilter | undefined }) => {
    if (_.isUndefined(filter)) {
      this.state.unset(['capsulePanelFilters', key]);
    } else {
      this.state.set(['capsulePanelFilters', key], filter);
    }
  };

  /**
   * Sets column headers. Used to determine if the column is a string column.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.headers - object containing column headers
   */
  private setCapsulePanelHeaders = (payload: { headers: TableColumnOutputV1[] }) => {
    this.state.set('capsulePanelHeaders', payload.headers);
  };

  /**
   * Sets the column and direction for sorting data in a panel.
   *
   * @param {Object} payload - An object container for arguments
   * @param {String} payload.panel - The name of the panel to sort
   * @param {String} payload.sortBy - The name of the column by which to sort
   * @param {Boolean} payload.sortAsc - True if sorting in ascending order, false otherwise
   */
  private setPanelSort = (payload) => {
    this.state.set(['panelSorters', payload.panel], _.omit(payload, 'panel'));
  };

  /**
   * Sets the specified CSS style properties on a panel.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.panel - Name of the panel.
   * @param {String} payload.props - Properties used by the panel, such as height, color, etc.
   */
  private setPanelProps = (payload) => {
    this.state.set(['panelProps', payload.panel], payload.props);
    this.state.commit(); // Ensures a smooth drag since the value changes onMouseMove
  };

  /**
   * Sets a region of the chart as selected, specified by two x-axis values. Specifying 0 values for min/max
   * will clear any previously selected region.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Number} payload.min - Lower x-value of selected region.
   * @param {Number} payload.max - Upper x-value of selected region.
   */
  private setSelectedRegion = (payload) => {
    const cursor = this.state.select('selectedRegion');
    if (cursor.get('min') !== payload.min || cursor.get('max') !== payload.max) {
      cursor.set({ min: payload.min, max: payload.max });
    }
  };

  /**
   * Sets the offsets used for the x-axis in capsule time.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Number} payload.lower - Lower offset in capsule time.
   * @param {Number} payload.upper - Upper offset in capsule time.
   */
  private setCapsuleTimeOffsets = (payload) => {
    this.state.set('capsuleTimeOffsets', payload);
  };

  /**
   * Toggles the visibility of dimmed capsules
   */
  private toggleHideUnselectedItems = () => {
    this.state.set('hideUnselectedItems', !this.state.get('hideUnselectedItems'));
  };

  /**
   * Sets whether capsule time is limiting the number of signals shown in order to maintain performance.
   *
   * @param {Object} payload - Object container for arguments
   * @param payload.capsuleTimeLimited - Capsule time limited information
   */
  private setCapsuleTimeLimited = (payload: { enabled: boolean; itemsCount: number; capsulesCount: number }) => {
    this.state.set('capsuleTimeLimited', payload);
  };

  /**
   * Sets the color mode for the lines in each lane of capsule time
   *
   * @param {Object} payload - Object container for arguments
   * @param {CapsuleTimeColorMode} payload.mode - One of the color modes
   */
  private setCapsuleTimeColorMode = (payload) => {
    this.state.set('capsuleTimeColorMode', payload.mode);
  };

  /**
   * Sets the color mode for compare-view
   *
   * @param {Object} payload - Object container for arguments
   * @param {CompareViewColorMode} payload.mode - One of the color modes
   */
  private setCompareViewColorMode = (payload: { mode: CompareViewColorMode }) => {
    this.state.set('compareViewColorMode', payload.mode);
  };

  /**
   * Toggles the dimming of data outside of the capsules
   */
  private toggleDimDataOutsideCapsules = () => {
    this.state.set('dimDataOutsideCapsules', !this.state.get('dimDataOutsideCapsules'));
  };

  /**
   * Sets the point value of an array of items.
   *
   * @param payload {Object} - Container for arguments
   * @param payload.yValues {Array} - Set of objects specifying current values for a point in multiple series
   * @param payload.yValues[].id - An id to specify the series that will be updated
   * @param payload.yValues[].pointValue - The value that should be displayed for this point on the series
   * @param payload.yValues[].pointSelected {Boolean} - Indicates that the current value should be emphasized when
   * true, deemphasized when false, and remain neutral when undefined
   */
  private setPointValue = (payload) => {
    this.state.set('pointValues', _.keyBy(payload.yValues, 'id'));
  };

  /**
   * Clears all point values for all items.
   */
  private clearPointValues = () => {
    this.state.set('pointValues', {});
  };

  /**
   * Controls the display of the signal names/units
   *
   * @param {Object} payload - Object container for arguments
   * @param {Boolean} payload.property - The property to set
   * @param {Boolean} payload.value - The value to set the property too, must be one of LABEL_LOCATIONS
   */
  private setLabelDisplayConfiguration = (payload) => {
    if (this.state.get(['labelDisplayConfiguration', payload.property]) !== payload.value) {
      // When the user changes this option reset the columns so that we keep the store clean
      if (payload.property === LABEL_PROPERTIES.CUSTOM) {
        this.state.set(['labelDisplayConfiguration', 'customLabels'], []);
      }

      this.state.set(['labelDisplayConfiguration', payload.property], payload.value);
    }
  };

  /**
   * Sets the property to color by in compare mode
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.colorByProperty - property to color by
   */
  private setColorByProperty = (payload) => {
    this.state.set('colorByProperty', payload.colorByProperty);
  };

  /**
   * Sets the list of property options to separate and/or color by in compare mode
   *
   * @param {Object} payload - Object container for arguments
   * @param {ReactSelectOption<string>[]} payload.capsuleProperties - list of property options
   */
  private setCapsuleProperties = (payload) => {
    this.state.set('capsuleProperties', payload.capsuleProperties);
  };

  /**
   * Sets the property to separate by in compare mode
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.separateByProperty - property to separate by
   */
  private setSeparateByProperty = (payload) => {
    this.state.set('separateByProperty', payload.separateByProperty);
  };

  /**
   * Sets the compare mode view for the trend
   */
  private setIsCompareMode = (payload) => {
    this.state.set('isCompareMode', payload.isCompareMode);
  };

  private setSummary = (payload: {
    type: string;
    value: number;
    isSlider: boolean;
    discreteUnits: FrontendDuration;
  }) => {
    payload.value = _.toNumber(payload.value);
    this.state.set('dataSummary', payload);
  };

  /**
   * Turns the display of the capsule set names in the capsule lanes on or off
   *
   * @param {Object} payload - Object container for arguments
   * @param {Boolean} payload.showCapsuleLabels - A flag indicating whether to show or hide the capsule name
   */
  private setShowCapsuleLabels = (payload) => {
    this.state.set('showCapsuleLaneLabels', payload.showCapsuleLabels);
  };

  /**
   * Swaps out any column definitions that reference items that have been swapped.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
   *   values are the corresponding swapped in ids.
   */
  private swapReferences = (payload) => {
    _.forEach(this.state.get('enabledColumns'), (columns, panel) => {
      _.forEach(columns, (columnDefinition, column) => {
        const swapInId = _.isObject(columnDefinition) ? payload.swaps[columnDefinition.referenceSeries] : undefined;
        if (swapInId) {
          this.state.unset(['enabledColumns', panel, column]);
          this.state.set(
            ['enabledColumns', panel, column.replace(columnDefinition.referenceSeries, swapInId)],
            _.assign({}, columnDefinition, {
              referenceSeries: swapInId,
              key: columnDefinition.key.replace(columnDefinition.referenceSeries, swapInId),
            }),
          );
        }
      });
    });
  };

  /**
   * Sets the first column in compare mode
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.firstColumn - first column
   */
  private setFirstColumn = (payload: { firstColumn: string }) => {
    this.state.set('firstColumn', payload.firstColumn);
  };

  /**
   *  Set the distinctStringValues Map for string column in capsules panel
   *
   * @param payload object container for arguments
   * @param payload.stringValueTable: table with distinct string values for a column
   * @param payload.columnKeyAndName: object with  columnKey (for the displayed table) and
   *  columnName (for the tables we got back from the calc engine)
   */
  private setStringColumnFilterValues = (payload: { stringValueTable; columnKeyAndName }) => {
    const tableData = payload.stringValueTable.data.table;
    const columnKey = payload.columnKeyAndName.columnKey;
    const columnName = payload.columnKeyAndName.columnName;
    this.state.set(['capsulePanelDistinctStringValueMap', columnKey], _.map(tableData, columnName));
  };

  /**
   * Adds or updates a custom label
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.location - the location of the target (lane or axis) one of LABEL_LOCATIONS
   * @param {String|Number} payload.target - the name of the axis or lane
   * @param {String} payload.text - the text to set the custom label
   */
  private setCustomLabel = (payload) => {
    const index = _.findIndex(
      this.state.get(['labelDisplayConfiguration', 'customLabels']),
      _.pick(payload, ['location', 'target']),
    );
    if (index === -1) {
      this.state.push(['labelDisplayConfiguration', 'customLabels'], payload);
    } else if (index >= 0) {
      this.state.set(['labelDisplayConfiguration', 'customLabels', index], payload);
    }
  };

  /**
   * Removes a custom label
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.location - the location of the target (lane or axis) one of LABEL_LOCATIONS
   * @param {String|Number} payload.target - the name of the axis or lane
   */
  private removeCustomLabel = (payload) => {
    const index = _.findIndex(this.state.get(['labelDisplayConfiguration', 'customLabels']), payload);
    if (index >= 0) {
      this.state.splice(['labelDisplayConfiguration', 'customLabels'], [index, 1]);
    }
  };

  /**
   * Sets capsule preview
   *
   * @param {Object} payload - Object container for arguments
   * @param {boolean} payload.capsulePreview - whether or not to display investigate range capsules
   */
  private setCapsulePreview = (payload) => {
    this.state.set('capsulePreview', payload.capsulePreview);
  };

  protected readonly handlers = {
    TREND_SET_VIEW: this.setView,
    TREND_SET_COLUMN_ENABLED: this.setColumnEnabled,
    TREND_ADD_PROPERTY_COLUMN: this.addPropertiesColumn,
    TREND_ADD_MULTIPLE_PROPERTY_COLUMNS: this.addMultiplePropertiesColumn,
    TREND_REMOVE_PROPERTY_COLUMN: this.removePropertiesColumn,
    TREND_REMOVE_ITEMS: this.removeItemColumns,
    TREND_RESET_CAPSULE_PANEL_OFFSET: this.resetCapsulePanelOffset,
    TREND_SET_COLUMN_FILTER: this.setColumnFilter,
    TREND_SET_STRING_COLUMN_FILTER_VALUES: this.setStringColumnFilterValues,
    TREND_SET_CAPSULE_PANEL_HEADERS: this.setCapsulePanelHeaders,
    TREND_SET_PANEL_PROPS: this.setPanelProps,
    TREND_SET_PANEL_SORT: this.setPanelSort,
    TREND_SET_CAPSULE_PANEL_OFFSET: this.setCapsulePanelOffset,
    TREND_SET_CAPSULE_PANEL_HAS_NEXT: this.setCapsulePanelHasNext,
    TREND_SET_CAPSULE_PANEL_IS_LOADING: this.setCapsulePanelIsLoading,
    TREND_SET_SELECTED_REGION: this.setSelectedRegion,
    TREND_SET_CAPSULE_TIME_OFFSETS: this.setCapsuleTimeOffsets,
    TREND_SET_CAPSULE_TIME_LIMITED: this.setCapsuleTimeLimited,
    TREND_SET_CAPSULE_TIME_COLOR_MODE: this.setCapsuleTimeColorMode,
    TREND_SET_COMPARE_VIEW_COLOR_MODE: this.setCompareViewColorMode,
    TREND_TOGGLE_HIDE_UNSELECTED_ITEMS: this.toggleHideUnselectedItems,
    TREND_TOGGLE_DIM_DATA_OUTSIDE_CAPSULES: this.toggleDimDataOutsideCapsules,
    TREND_SET_X_VALUE: this.setXValue,
    TREND_SET_POINT_VALUE: this.setPointValue,
    TREND_CLEAR_POINT_VALUES: this.clearPointValues,
    TREND_SET_LABEL_DISPLAY_CONFIG: this.setLabelDisplayConfiguration,
    TREND_SET_CUSTOMIZATION_MODE: this.setCustomizationMode,
    TREND_SET_GRIDLINES: this.setGridlines,
    TREND_SET_COLOR_BY_PROPERTY: this.setColorByProperty,
    TREND_SET_SEPARATE_BY_PROPERTY: this.setSeparateByProperty,
    TREND_SET_CAPSULE_PROPERTIES: this.setCapsuleProperties,
    TREND_SET_FIRST_COLUMN: this.setFirstColumn,
    TREND_SET_IS_COMPARE_MODE: this.setIsCompareMode,
    TREND_SET_SUMMARY: this.setSummary,
    TREND_SET_SHOW_CAPSULE_LABELS: this.setShowCapsuleLabels,
    TREND_SWAP_ITEMS: this.swapReferences,
    TREND_SET_CUSTOM_LABEL: this.setCustomLabel,
    TREND_REMOVE_CUSTOM_LABEL: this.removeCustomLabel,
    TREND_SET_CAPSULE_PREVIEW: this.setCapsulePreview,
  };

  /**
   * Dehydrates the item by retrieving the current set parameters in view
   *
   * @returns {Object} An object with the state properties as JSON
   */
  dehydrate() {
    return _.omit(this.state.serialize(), [
      'xValue',
      'pointValues',
      'capsulePanelIsLoading',
      'capsulePanelDistinctStringValueMap',
      'capsuleTimeLimited',
      'capsulePanelHeaders',
    ]);
  }

  /**
   * Rehydrates item from dehydrated state
   *
   * @param {Object} dehydratedState - State object that should be restored
   */
  rehydrate(dehydratedState) {
    this.state.deepMerge(dehydratedState);
  }
}
